<template>
  <div style="flex: 1; display: flex; flex-direction: column" class="mx-2">
    <template v-if="convo">
      <div style="display: flex; align-items: center;" class="ml-2">
        <!-- <v-tooltip location="top" text="Add emoji (feature in development)">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small">mdi-emoticon-outline</v-icon>
          </template>
</v-tooltip> -->
        <!-- <v-tooltip location="top" text="Add image (feature in development)">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" size="small">mdi-image-outline</v-icon>
          </template>
        </v-tooltip> -->
        <v-tooltip location="top" text="Upload file (feature in development)">
          <template v-slot:activator="{ props }">
            <v-btn variant="flat" style="width: 40px; padding: 0; min-width: 0;">
              <v-icon v-bind="attrs" v-on="on" @click="triggerFileInput" size="large">mdi-file</v-icon>
            </v-btn>
            <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;" />
          </template>
        </v-tooltip>
        <v-tooltip location="top" text="Upload file (feature in development)">
          <template v-slot:activator="{ props }">
            <v-btn variant="flat" style="width: 40px; padding: 0; min-width: 0;">
              <v-icon v-bind="attrs" v-on="on" @click="getLocation" size="large">mdi-map-marker</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
        <div>
          <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn v-bind="activatorProps" variant="flat" style="width: 40px; padding: 0; min-width: 0;">
                <v-icon size="large">mdi-microphone</v-icon>
              </v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="Audio">
                <div class="audio-recorder" style="display: flex; align-items: center;">
                  <div style="margin-left: 6%;">
                    <button @click="startRecording" v-if="!isRecording">
                      <v-icon>mdi-microphone</v-icon>
                    </button>
                    <button @click="stopRecording" v-else-if="isRecording">
                      <v-icon>mdi-record</v-icon>
                    </button>
                  </div>
                  <div v-if="isRecording" class="wavy">
                    <span></span><span></span><span></span><span></span><span></span>
                  </div>
                  <audio v-if="audioUrl" :src="audioUrl" controls></audio>
                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text="Send" @click="() => { isActive.value = false; messageInput(); }"></v-btn>
                  <v-btn text="Close" @click ="() => {isActive.value = false; clearPhoto();}"></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div>
          <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn v-bind="activatorProps" variant="flat" style="width: 40px; padding: 0; min-width: 0;">
                <v-icon size="large">mdi-camera</v-icon>
              </v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="Camera">
                <v-card-text>


                  <v-icon large @click="openCamera">mdi-camera</v-icon>
                  <video v-if="isCameraOpen" ref="video" width="100%" autoplay></video>
                  <canvas ref="canvas" style="display:none;"></canvas>
                  <v-btn v-if="isCameraOpen" @click="capturePhoto" variant="flat">Capture Photo</v-btn>
                  <img v-if="photo" :src="photo" alt="Captured Photo" style="width: 100px;" />
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text="send" @click="() => { isActive.value = false; messageInput(); }"></v-btn>
                  <v-btn text="Close" @click ="() => {isActive.value = false; clearPhoto();}"></v-btn>

                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <div>
          <v-dialog max-width="500">
            <template v-slot:activator="{ props: activatorProps }">
              <v-btn v-bind="activatorProps" variant="flat" style="width: 40px; padding: 0; min-width: 0;">
                <v-icon size="large">mdi-video</v-icon>
              </v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <v-card title="Video">
                <div style="margin-left: 6%;">
                  <v-icon v-if="!isVideoOn" @click="startVideoRecording">mdi-video</v-icon>
                  <v-icon v-if="isVideoOn" @click="stopVideoRecording">mdi-stop</v-icon>
                </div>
                <video v-if="isVideoOn && !recordedVideoUrl" ref="video" autoplay></video>
                <video v-if="recordedVideoUrl" :src="recordedVideoUrl" style="width: 400px;" controls></video>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text="send" @click="() => { isActive.value = false; messageInput(); }"></v-btn>
                  <v-btn text="Close" @click ="() => {isActive.value = false; clearPhoto();}"></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>

      </div>
      <div v-if="referencedConvo.media_id == null && referencedConvo.message"
        style="margin-bottom: 3px;background-color: #ebeced;">
        {{ referencedConvo.message }}
      </div>
      <div v-else-if="referencedConvo.convo_message_id" style="margin-bottom: 3px;background-color: #ebeced;">
        <v-img v-on:click="openImg(fetchImg(referencedConvo.convo_message_id,referencedConvo.media_id))" max-height="120" max-width="200"
        :src="imgSrc"></v-img>
      </div>
      <audio style="margin: 2%;" v-if="audioUrl" :src="audioUrl" controls></audio>
      <v-list v-if="files.length > 0">

        Selected files:
        <v-list-item v-for="(file, index) in files" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ file.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <div style="flex: 1">
      <v-menu :model-value="toggleMention && filteredUsers.length > 0 && !disablePopup
        " max-width="200" scroll-strategy="none" location="top" :offset="[0, -caretPosition.left]">
        <template v-slot:activator="{ props }">
          <v-textarea v-bind="props" v-model="message" @click:append-inner="messageInput()"
            @click:append="reminderClicked()" @keyup="calculateMention" @keydown="checkKeyPressed"
            :disabled="(convo && !convo.convo_status) || disableTextarea" rows="1" append-inner-icon="mdi-send"
            append-icon="mdi-alarm" auto-grow filled rounded variant="solo-filled" style="margin-left: 10px;"
            placeholder="Type message" ref="textarea" id="vtextarea">
          </v-textarea>
        </template>
        <RecycleScroller v-show="toggleMention && filteredUsers.length > 0 && !disablePopup" :items="filteredUsers"
          :minItemSize="25" :item-size="45" key-field="user_id" v-slot="{ item }" class="text-caption mention">
          <v-hover v-slot="{ hover }">
            <div @click="userSelected(item)" style="display: flex; align-items: center; height: 45px" class="pl-2"
              :class="hover ? 'highlighted' : ''">
              <UserPopup noMenu dense :user_id="item.user_id" style="flex: 1" />
              <div style="
                  flex: 4;
                  display: flex;
                  flex-direction: column;
                  height: 45px;
                  justify-content: center;
                ">
                <span style="height: 15px; font-size: 13px" class="ml-2">{{
                  item.username
                }}</span>
                <span style="font-size: 9px" class="ml-2">{{
                  item.first_name + " " + item.last_name
                }}</span>
              </div>
            </div>
          </v-hover>
        </RecycleScroller>
      </v-menu>
    </div>
  </div>
</template>

<script>
import UserPopup from "@/components/Avatar/UserPopup";
import { mapGetters, mapMutations } from "vuex";
import axios from 'axios'
import { nextTick } from 'vue';
import ConvoSerice from "@/services/convoService";
import eventBus from "@/eventBus";


// Will use this function from textarea-caret package
let getCaretCoordinates = require("textarea-caret");

export default {
  name: "MessengerBox",
  components: {
    UserPopup,
  },
  props: {
    convo: { type: Object, default: null },
  },
  data() {
    return {
      message: "",
      mention: "",
      toggleMention: false,
      // This is how we will identify where to place the @mentions pop-up, using the textarea-caret package
      // we will get the values of the fields 'left' and 'top,' while we will calculate 'topAdjusted'
      // ourselves based on the value we received for 'top.' Then, we will position the v-menu using the 'left'
      // and 'topAdjusted' fields, nudging the v-menu to the right by 'left' pixels and nudging the v-menu
      // to the bottom by 'topAdjusted pixels.'
      caretPosition: { left: 0, top: 0, topAdjusted: 0 },
      currMentionStartIndex: null,
      oldTop: 5,
      edgeCaseAdjustment: 0,
      prevAdjustmentResult: 3,
      textAreaHeight: null,
      mentionLength: -1,
      // This will be an object array that will hold all the mentions,
      // Each object will have the following fields:
      // 2) starting index of mention
      // 3) ending index of mention
      mentions: [],
      // Will hold the index of all the mentions that need to have their starting and
      // ending index update due to the message text either increasing or decreasing in
      // size.
      mentionIndicesToUpdate: [],
      disableTextarea: false, //prolly get rid of this!
      cursorOverMention: false,
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      files: [],
      location: null,
      audioBlob: '',
      photo: null,
      mediaRecorder: null,
      recordedChunks: [],
      recordedVideoUrl: null,
      isVideoOn: false,
      isCameraOpen: false,
      videoRef: false,
      latitude: '',
      longitude: '',
      address: '',
      city: '',
      country: '',
      imgSrc: null,
    };
  },
  mounted() {
    // this.$refs.video.addEventListener('click', this.capturePhoto);
  },
  created(){
    eventBus.$on("referenced",()=>{
      this.fetchImg(this.referencedConvo.convo_message_id,this.referencedConvo.media_id)
    })
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapGetters("people", ["users"]),
    ...mapGetters("convos", ["referencedConvo"]),
    // filters the users based on the mention text
    filteredUsers: function () {
      if (!this.users) {
        return [];
      } else {
        let result = this.users.filter((user) => {
          if (user.last_name) {
            const fullName = user.first_name + " " + user.last_name;
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              fullName.toLowerCase().startsWith(this.mention.toLowerCase()) ||
              user.last_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          } else {
            return (
              user.username
                .toLowerCase()
                .startsWith(this.mention.toLowerCase()) ||
              user.first_name
                .toLowerCase()
                .startsWith(this.mention.toLowerCase())
            );
          }
        });

        let mentionSplit = this.mention.split(" ");
        //console.log("cursor index: "+this.cursorIndex());
        //console.log("mention split length: "+mentionSplit[0].length);
        //console.log("currMentionStartIndex: "+this.currMentionStartIndex);
        let mentionIndex =
          parseInt(this.currMentionStartIndex) +
          parseInt(mentionSplit[0].length);
        //console.log("mention index: "+mentionIndex);
        if (result.length > 0 || this.cursorIndex() > mentionIndex) {
          return result;
        } else {
          return this.users.filter((user) => {
            //console.log(mentionSplit);
            if (user.last_name) {
              const fullName = user.first_name + " " + user.last_name;
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                fullName
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.last_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            } else {
              return (
                user.username
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase()) ||
                user.first_name
                  .toLowerCase()
                  .startsWith(mentionSplit[0].toLowerCase())
              );
            }
          });
        }
      }
    },
    showVMenu: function () {
      return (
        this.toggleMention &&
        this.filteredUsers.length > 0 &&
        !this.disablePopup
      );
    },
  },
  watch: {
    message: function () {
      if (this.message == "") this.calculateMention();
    },
    showVMenu: function () {
      if (this.showVMenu == false) {
        this.edgeCaseAdjustment = 0;
      }
    },
    mention: function () {
      if (this.filteredUsers.length == 1 && this.toggleMention) {
        let fullName;
        if (this.filteredUsers[0].last_name) {
          fullName =
            this.filteredUsers[0].first_name.toLowerCase() +
            " " +
            this.filteredUsers[0].last_name.toLowerCase();
        } else {
          fullName = null;
        }
        if (
          this.filteredUsers[0].username.toLowerCase() ==
          this.mention.toLowerCase() ||
          this.filteredUsers[0].first_name.toLowerCase() ==
          this.mention.toLowerCase() ||
          fullName == this.mention.toLowerCase()
        ) {
          this.userSelected(this.filteredUsers[0]);
        }
      }
    },
  },
  methods: {
    ...mapMutations("convos", ["changeReferencedConvo"]),
    openImg(url) {
      window.open(url);
    },
    async fetchImg(convo_message_id, media_id) {
      let res = await ConvoSerice.getConvoMediaUrl(convo_message_id, media_id)
      console.log("url",res)
      this.imgSrc = res;
    },
    async openCamera() {
      try {
        this.isCameraOpen = true;
        await this.$nextTick();
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
      } catch (err) {
        console.error('Error accessing the camera', err);
      }
    },
    capturePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      if (video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo = canvas.toDataURL('image/jpeg');

        // Create a file from the data URL
        this.createFileFromDataURL(this.photo);

        // Stop the video stream and hide the video element
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        this.isCameraOpen = false;
      } else {
        console.error("Video dimensions are not available");
      }
    },
    async startVideoRecording() {
      this.isVideoOn = true;
      this.recordedChunks = [];

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio:true });
          if (this.$refs.video) {
            this.$refs.video.srcObject = stream;
          } else {
            console.error("Video element reference is not defined.");
            return;
          }

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
            this.recordedVideoUrl = URL.createObjectURL(blob);

            // Create a file from the recorded video and add it to the files array
            let filename = 'video_' + Date.now() + '.webm';
            const file = new File([blob], filename, { type: 'video/webm' });
            this.files.push(file);

            this.recordedChunks = [];
          };

          this.mediaRecorder.start();
        } catch (error) {
          console.error("Error accessing media devices.", error);
        }
      } else {
        console.error("Media devices are not supported in this browser.");
      }
    },
    stopVideoRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
        this.mediaRecorder.stop();

        // Ensure the video element is defined
        if (this.$refs.video) {
          const stream = this.$refs.video.srcObject;
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          this.$refs.video.srcObject = null;
        } else {
          console.error("Video element reference is not defined.");
        }
        this.isVideoOn = false
      }
    },
    createFileFromDataURL(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ab], { type: mimeString });
      const timestamp = new Date().getTime(); // Get current timestamp
      const fileName = `captured_photo_${timestamp}.jpeg`;
      const file = new File([blob], fileName, { type: "image/jpeg" });

      this.files = []
      this.files.push(file);
      this.getLocation()
      console.log("Stored file:", this.files);
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        this.error = "Geolocation is not supported by this browser.";
      }
    },
    showPosition(position) {
      const { latitude, longitude } = position.coords;
      this.latitude = latitude;
      this.longitude = longitude;
      this.location = {
        latitude,
        longitude,
        address: '',
        city: '',
        country: '',
      };
      this.getAdditionalLocationInfo(latitude, longitude);
    },
    async getAdditionalLocationInfo(latitude, longitude) {
      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`
        );
        const result = response.data;
        console.log("result", result)
        if (result) {
          this.message = `Latitude: ${latitude}, Longitude: ${longitude}, Address: ${result.display_name}, City: ${result.address.city || result.address.town || result.address.village}, Country: ${result.address.country} `
          this.message = this.message.replace(/[^\x00-\x7F]/g, '');
          this.location.address = result.display_name;
          this.location.city = result.address.city || result.address.town || result.address.village;
          this.location.country = result.address.country;
          this.address = result.display_name;
          this.city = result.address.city || result.address.town || result.address.village;
          this.country = result.address.country;
        }
      } catch (error) {
        console.log(error)
        alert("error in accessing location!")
        this.error = "Error fetching location details.";
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.files = Array.from(event.target.files);
    },
    async startRecording() {
      this.audioChunks = [];
      this.getLocation()
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);

      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };

      this.mediaRecorder.onstop = () => {
        const audio_blob = new Blob(this.audioChunks, { type: 'audio/wav' });
        this.audioUrl = URL.createObjectURL(audio_blob);
        this.audioBlob = audio_blob;
      };

      this.mediaRecorder.start();
      this.isRecording = true;
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
    },

    async messageInput() {
      let has_attachment = this.files != null && this.files.length > 0;
      try {
        if (this.message !== "" || has_attachment || this.audioUrl) {
          const formData = new FormData();
          if (this.files != null) {
            for (var i = 0; i < this.files.length; i++) {
              formData.append("files", this.files[i]);
            }
          }
          let extractedUrl = null
          if (this.audioUrl != null) {
            extractedUrl = this.audioUrl.split("blob:")[1];
            let filename = 'audio_' + Date.now() + '.wav';
            formData.append("files", this.audioBlob, filename);
          }
          formData.append("message", this.message);
          if (this.location) {
            formData.append("latitude", this.latitude)
            formData.append("longitude", this.longitude)
            formData.append("address", this.address)
            formData.append("city", this.city)
            formData.append("country", this.country)
          }
          if (this.referencedConvo.convo_message_id) {
            formData.append("ref", this.referencedConvo.convo_message_id)
          }
          this.$emit("messageSent", formData);
          this.changeReferencedConvo({})
          this.message = "";
          this.files = [];
          this.photo = null;
          this.audioUrl = null;
          this.$nextTick(() => {
            this.$refs.textarea.$el.querySelector('textarea').value = "";
          });
        }
      } catch (e) {
        console.log("errot", e)
      }
    },
    async userSelected(user) {
      let messageFirstHalf = this.message.slice(0, this.currMentionStartIndex);
      let mentionSplit = this.mention.split(" ");
      let messageSecondHalf;
      //console.log("in user selected: "+this.mention);
      if (mentionSplit.length > 1 && mentionSplit[1].length > 0) {
        if (
          this.filteredUsers.filter((e) => {
            if (e.last_name) {
              let fullName =
                e.first_name.toLowerCase() + " " + e.last_name.toLowerCase();
              return fullName.startsWith(this.mention.toLowerCase());
            } else {
              return false;
            }
          }).length > 0
        ) {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + this.mention.length
          );
        } else {
          messageSecondHalf = this.message.slice(
            this.currMentionStartIndex + mentionSplit[0].length
          );
        }
      } else {
        messageSecondHalf = this.message.slice(
          this.currMentionStartIndex + mentionSplit[0].length
        );
      }

      // Adding 6 spaces here to the message to account for the extended mention ui length
      // and 1 space extra so user doesn't have to hit spacebar.
      // Sike it's just 5 spaces actually.
      // Update: Ending up just adding 1 extra space to do the additional automatic spacebar after a mention
      // for the user.
      this.message = messageFirstHalf + user.username + " " + messageSecondHalf;

      // Getting starting and ending indices of mention
      let startIndex = parseInt(messageFirstHalf.length) - 1;
      let endIndex = startIndex + parseInt(user.username.length) + 1;
      console.log("startindex: " + startIndex);
      console.log("endindex: " + endIndex);

      // Toggle mention to false as we do not need the mention popup anymore
      this.toggleMention = false;

      // Creating new mention object
      let newMention = {
        start: startIndex,
        end: endIndex,
      };

      // Adding mention to our mentions object array
      this.mentions.push(newMention);

      // Here we are temporarily disabling the popup after the mention was created
      // so that if the person's username is also the first name of the individual
      // who was just mentioned, the pop up will not keep showing after they hit the
      // spacebar (since our program will then assume they are trying to still
      // pick the person to mention by typing in their last name, when in reality
      // the user had already picked the mention).
      this.disablePopup = true;
    },
    reminderClicked() {
      this.$emit("reminderClicked");
    },
    checkKeyPressed(event) {
      if (event && event.key == "@") {
        // If the key pressed was an @ symbol we can re-enable to popup to show.
        this.disablePopup = false;
      }
    },
    calculateMention(event) {
      let b = [
        ...this.message.matchAll(/\B(@([a-z0-9_-]+)( ([a-z0-9_-]+))?)|(@)/gi),
      ];
      // An array of entries for each mention in the text, where each entry is itself
      // an array of size 2, the first element indicating the starting cursor index of that
      // mention and the second element indicating the ending cursor index of that mention.
      let mentionIndices = b.map((a) => [a.index + 1, a.index + a[0].length]);
      //console.log(mentionIndices);

      if (mentionIndices.length == 0) return;

      let cursorIndex = event.target.selectionEnd;
      console.log('cursorindex', cursorIndex)
      let caret = getCaretCoordinates(event.target, cursorIndex);

      let currTextAreaHeight = event.target.style?.height || 32;

      //console.log("Curr cursor index: " + this.cursorIndex());

      // getting the current mention if the user's cursor is over a portion of text that
      // requires a mention/is an unfinished mention (will hold start index of the mention
      // as the first element, and end index as the second element, currMention is a
      // two-element array).
      const currMention = mentionIndices.findIndex(
        (a) => cursorIndex >= a[0] && cursorIndex <= a[1]
      );

      //console.log("Curr mention return val: " + currMention);

      if (currMention >= 0) {
        this.currMentionStartIndex = mentionIndices[currMention][0]; //added this!
        this.mention = this.message.substring(
          mentionIndices[currMention][0],
          mentionIndices[currMention][1]
        );

        let atSymbol = getCaretCoordinates(
          event.target,
          mentionIndices[currMention][0] - 1
        );

        this.caretPosition = atSymbol;

        // edge case stuff
        if (this.textAreaHeight && this.textAreaHeight < currTextAreaHeight) {
          if (this.caretPosition.top > 33) {
            this.edgeCaseAdjustment = 25;
          } else {
            this.edgeCaseAdjustment = 10;
          }
          console.log("down");
        } else if (
          this.textAreaHeight &&
          this.textAreaHeight > currTextAreaHeight
        ) {
          this.edgeCaseAdjustment = 0;
          console.log("up");
        }

        // The 45 here is the height of each item in the RecycleScroller (the value of :itemSize prop)
        if (this.filteredUsers.length * 45 < 200) {
          this.caretPosition.topAdjusted =
            this.caretPosition.top -
            this.filteredUsers.length * 45 -
            10 -
            this.edgeCaseAdjustment; // Minus 10 for buffer, so pop-up not right on top of words.
        } else {
          this.caretPosition.topAdjusted = this.caretPosition.top - 200;
        }
        this.toggleMention = true;
      } else {
        this.toggleMention = false; // User cursor not over a mention
      }

      this.textAreaHeight = currTextAreaHeight;
      this.oldTop = caret.top;

      return mentionIndices;
    },
    // gets the cursor index in the v-textarea
    cursorIndex() {
      try {
        let index =
          this.$refs.textarea.$el.$el.children[0].children[0].children[2]
            .children[1].selectionStart;

        return index;
      } catch (e) {
        return 0;
      }
    },
    async clearPhoto() {
       try {
        
        this.message = "";
        this.files = [];
        this.photo = null;
        this.audioUrl = null;
        this.$nextTick(() => {
          this.$refs.textarea.$el.querySelector('textarea').value = "";
        });
      } catch (e) {
        console.log("error", e)
      }
    },
  },

  
};
</script>

<style scoped>
.mention {
  width: 200px;
  max-height: 200px;
  background: white;
  border: 5px solid #f0f0f0;
  text-align: left;
}

.wavy {
  display: flex;
  align-items: center;
}

.wavy span {
  display: inline-block;
  width: 5px;
  height: 10px;
  margin: 0 2px;
  background: #f00;
  animation: wave 1.2s linear infinite;
}

.wavy span:nth-child(1) {
  animation-delay: 0s;
}

.wavy span:nth-child(2) {
  animation-delay: 0.2s;
}

.wavy span:nth-child(3) {
  animation-delay: 0.4s;
}

.wavy span:nth-child(4) {
  animation-delay: 0.6s;
}

.wavy span:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes wave {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}
</style>
